import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import Actualites from '../components/PagesComponent/Actualites/Actualites';

function ActualitesPage() {
  return (
    <Container>
      <SEO
        title="Hairun Technology - Nos actualités"
        description="Découvrez les actus de Hairun Technology, des astuces et pleins d'autres items autour de la technologie et du digital"
      />
      <Actualites />
    </Container>
  );
}

export default ActualitesPage;
