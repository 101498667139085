import { makeStyles } from '@material-ui/core';
import Bg from '../../../images/element_blog.svg';

export default makeStyles((theme) => ({
  actualites: {},
  topBox: {
    position: 'relative',
    paddingTop: 64,
    paddingBottom: 64,
    '@media (max-width: 576px)': {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '0',
    backgroundPositionY: '0',
  },
  titrePage: {},
  descriptionPage: {
    fontSize: '1.56250rem',
    letterSpacing: '0.63px',
    fontFamily: 'Poppins',
    maxWidth: 873,
    textAlign: 'center',
  },
  formSearch: {
    padding: 10,
    marginTop: 25,
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '100%',
      maxWidth: 998,
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 4,
      backgroundColor: '#F1F1F1',
    },
    '& fieldset': {
      border: 0,
    },
    '& input': {
      paddingTop: 11,
      paddingBottom: 11,
      fontSize: '.825rem',
      color: '#808080',
    },
    '& input::placeholder': {
      color: '#808080',
    },
  },
  listing: {
    maxWidth: 1800,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 992px)': {
      columnCount: 1,
      display: 'flex',
      flexWrap: 'wrap',
    },
    '@media (max-width: 576px)': {
      columnCount: 1,
    },
  },
  mansory: {
    columnCount: 3,
    '& > div': {
      display: 'grid',
      gridTemplateRows: '1fr auto',
      marginBottom: 10,
      breakInside: 'avoid',
    },
  },

  pagination: {
    color: '#272727',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: 'Poppins',
    '& span': {
      padding: '10px',
      '@media (max-width: 576px)': {
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
      },
    },
    '& svg': {
      fill: '#CCCCCC',
    },
    '& button:hover svg': {
      fill: 'inherit',
    },
  },
}));
