import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ActusItem from './ActusItem';
import { Box, Button, Typography, TextField } from '@material-ui/core';
import styles from './style';
import Layout from '../../Common/Layout';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';

function Actualites() {
  try {
    const data: {
      allWpPost: {
        edges: [
          {
            node: {
              id: string;
              title: string;
              excerpt: string;
              slug: string;
              date: string;
              featuredImage: {
                node: {
                  sourceUrl: string;
                };
              };
            };
          },
        ];
      };
    } = useStaticQuery(graphql`
      query {
        allWpPost {
          edges {
            node {
              title
              excerpt
              slug
              date(formatString: "DD MMMM yyyy", locale: "fr-FR")
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `);
    const classes = styles();

    const datas = data.allWpPost.edges;

    const [posts, setPosts] = useState(datas);
    const [value, setValue] = useState('');
    const handleDelete = () => {
      setPosts(datas);
      setValue('');
    };
    const handleRemove = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.keyCode === 8 || !value) {
        setPosts(datas);
      }
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      const regex = new RegExp(event.target.value, 'ig');
      setPosts((posts: any) => {
        return posts.filter(({ node }: any) => {
          return regex.test(node.title);
        });
      });
    };

    return (
      <Box className={classes.actualites}>
        <Box className={classes.topBox}>
          <Box className={classes.background} />
          <Layout>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h1"
                className={classnames('titreSection', classes.titrePage)}
              >
                Nos actualités
              </Typography>
              <Typography
                className={classnames(
                  'textParagraphe',
                  classes.descriptionPage,
                )}
              >
                Découvrez les actus de Hairun Technology, des astuces et pleins
                d'autres items autour de la technologie et du digital.
              </Typography>

              <Box className={classes.formSearch}>
                <TextField
                  placeholder="Rechercher un article..."
                  onChange={handleSearch}
                  onKeyUp={handleRemove}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Layout>
        </Box>
        <Layout>
          <Box className={classnames(classes.mansory, classes.listing)}>
            {posts.length &&
              posts.map(({ node }, index) => {
                // if (index < 9) {
                const image = node.featuredImage.node.sourceUrl;
                return (
                  <ActusItem
                    key={+node.id}
                    title={node.title}
                    image={image}
                    intro={node.excerpt}
                    date={node.date}
                    url={`/actualites/${node.slug}`}
                  />
                );
                // }
              })}
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Box component="span">1-9 sur 450</Box>
            <Button>
              <FirstPageIcon />
            </Button>
            <Button>
              <LastPageIcon />
            </Button>
            <Button>
              <ChevronLeftIcon />
            </Button>
            <Button>
              <ChevronRightIcon />
            </Button>
          </Box>
        </Layout>
      </Box>
    );
  } catch (err) {
    return <></>;
  }
}

export default Actualites;
