import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    '@media (max-width: 992px)': {
      maxWidth: '50%',
    },
    '@media(max-width:576px)': {
      maxWidth: '100%',
      height: 'auto',
      flexDirection: 'column-reverse',
    },
    '&>a': { display: 'inline-block' },
    '&>*': {
      '@media(max-width:576px)': {},
    },
    '&:last-child': {
      '@media (min-width: 992px)': {
        flexDirection: 'column-reverse',
      },
      height: 'inherit',
      '&>*': {
        maxWidth: '100%',
      },
    },
  },
  imgActus: (props: any) => ({
    // background: `url(${props.image}) left center /cover no-repeat`,
    '& img': {
      width: '100%',
      '@media(max-width:576px)': {
        display: 'block',
        // background: 'none',
      },
    },
    '@media(max-width:576px)': {},
  }),
  actusContent: {
    display: 'inline-block',
    padding: '25px 14px',
    color: '#272727',
    '@media (max-width: 992px)': {
      padding: '10px',
    },
    '@media(max-width:576px)': {
      minWidth: '100%',
      padding: '10px 0',
    },
    '& h2': {
      marginBottom: theme.spacing(2),
      fontWeight: 500,
      fontSize: '1.250rem',
      letterSpacing: '0.18px',
      fontFamily: 'ubuntu',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      '@media (max-width: 1199px)': {
        fontSize: '1.5rem',
      },
      '@media (max-width: 576px)': {
        fontSize: '1.15rem',
      },
    },
    '& p': {},
    '& a': {
      width: 'auto',
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      marginTop: 'auto',
      '& button': {
        borderRadius: 4,
        borderColor: '#D34246',
        color: '#D34246',
        transition: '50ms ease-in',
        '@media(max-width:576px)': {
          marginTop: theme.spacing(4),
        },
        '&:hover': {
          borderColor: '#D34246',
          paddingLeft: 20,
          paddingRight: 20,
          transition: '50ms ease-in',
        },
        '& svg': {
          transition: '50ms ease-in',
        },
        '&:hover svg': {
          transition: '50ms ease-in',
          transform: 'translateX(5px)',
        },
      },
    },
  },
  textElipsis: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    '@media(max-width:576px)': {
      '-webkit-line-clamp': 2,
    },
    '& p': {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 3,
      fontSize: '1rem',
      color: '#808080',
      marginTop: 5,
      marginBottom: 5,
      '@media (max-width: 576px)': {
        fontSize: '0.825rem',
      },
      overflow: 'hidden',
      '@media(max-width:576px)': {
        '-webkit-line-clamp': 2,
      },
    },
  },
  meta: {
    fontSize: '0.75rem',
    marginBottom: 15,
    '& svg': {
      fontSize: '0.825rem',
      marginRight: 10,
    },
    '& span': {
      textTransform: 'uppercase',
      backgroundColor: '#F5381A',
      color: '#fff',
      borderRadius: 3,
      padding: '0px 9px',
      marginRight: 10,
    },
  },
  divider: {
    borderBottom: '1px solid #808080',
    marginTop: 15,
  },
}));
